.portfolioContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 160px;
}

.portfolio {
    width: 84.2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.portfolio h3 {
    font-family: 'RalewayExtraBold';
    font-size: 40px;
    margin-bottom: 16px;
    margin: 0;
    user-select: none;
}

.projectsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 70px;
    gap: 60px;
    z-index: 1;
}

.projectContainer {
    background: linear-gradient(137deg, rgba(173,26,175,1) 8%, rgba(154,26,156,1) 43%, rgba(142,13,180,1) 65%, rgba(118,4,175,1) 100%); 
    width: 350px;
    height: 490px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    height: 99%;
    border-radius: 1rem;
    background-color: #140c1f;
}

.content {
    width: 87%;
}

.status {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.status svg {
    color: rgb(255, 255, 255, 0.5);
    font-size: 1.8rem;
    cursor: pointer;
}

.projectStatusLive {
    color: #4AE290;
    background-color: rgb(74, 226, 144, 0.3);
    font-size: 18px;
    font-family: 'RalewayBold';
    padding: 8px 22px;
    border-radius: .6rem;
    user-select: none;
}

.projectStatusPending {
    color: #e97020;
    background-color: rgb(233, 112, 32, 0.3);
    font-size: 18px;
    font-family: 'RalewayBold';
    padding: 8px 22px;
    border-radius: .6rem;
    user-select: none;
}

.content p {
    color: rgb(255, 255, 255, 0.5);
    font-family: 'RalewayLight';
    font-size: 16px;
    word-spacing: 1.5px;
    margin-top: 0;
}

.content img {
    border-radius: .8rem;
    width: 300px;
    height: 200px;
    margin-bottom: 10px;
    user-select: none;
}

.content h3 {
    color: #b8b8b8;
    font-family: 'RalewayBold';
    font-size: 20px;
    margin-bottom: 10px;
    user-select: none;
    margin: 20px 0 10px 0;
}

.content a {
    text-decoration: none;
    color: #b8b8b8;
    font-family: 'RalewayBold';
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    user-select: none;
    margin-left: 50%;
}

.content a:hover {
    color: rgb(230, 230, 230);
    transition: all 0.2s ease-in-out;
}

.projectLink svg {
    font-size: .9rem;
}

.technologies {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: 'RalewayBold';
    font-size: 16px;
    color: #AD1AAF;
    user-select: none;
    margin-top: 10px;
}

.githubLink {
    font-size: 20px;
    font-family: 'RalewaySemiBold';
    text-decoration: none;
    color: rgb(173, 26, 175, 0.6);
    transition: all 0.2s ease-in-out;
    margin-top: 40px;
    position: relative;
}

.githubLink:hover {
    color: rgb(173, 26, 175, 1);
    transform: all 0.2s ease-in-out;
}

@media (max-width: 1400px) {
    .projectContainer {
        width: 310px;
    }

    .content img {
        width: 260px;
        height: 170px;
    }

    .content a {
        font-size: 16px;
        white-space: nowrap;
    }

    .projectStatusPending {
        font-size: 16px;
    }

    .projectStatusLive {
        font-size: 16px;
    }
}

@media (max-width: 850px) {
    .portfolio h3 {
        font-size: 35px;
    }

    .content h3 {
        font-size: 18px;
    }
}

@media (max-width: 810px) {
    .projectContainer {
        width: 280px;
        height: 460px;
    }

    .content img {
        width: 235px;
        height: 140px;
    }

    .content a {
        margin-left: 48%;
    }

    .projectStatusPending {
        font-size: 13px;
    }
}

@media (max-width: 715px) {
    .projectsContainer {
        gap: 30px;
    }
}

@media (max-width: 680px) {
    .projectsContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }

    .githubLink {
        margin-top: 35px;
        margin-left: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .portfolio h3 {
        font-size: 30px;
        white-space: nowrap;
    }

    .content h3 {
        font-size: 18px;
    }
}

@media (max-width: 400px) {
    .portfolioContainer {
        margin-top: 100px;
    }

    .portfolio {
        align-items: center;
    }

    .projectsContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }
}