.presentation {
    display: flex;
    width: 84.2%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    position: relative;
    z-index: 3;
    gap: 50px;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
    position: relative;
}

.presentationElement {
    padding: 5px 0;
}

.name {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.greeting {
    background-color: #2b1d23;
    text-align: center;
    font-family: 'RalewayBold';
    color: #AD1AAF;
    padding: 6px 20px 11px 20px;
    border-radius: .8rem;
    font-size: 20px;
    margin-bottom: 20px;
    user-select: none;
}

.name h1 {
    font-size: 52px;
    font-family: 'RalewayExtraBold';
    margin: 0;
}

.name h2 {
    font-size: 20px;
    font-family: 'RalewayRegular';
    margin: 0;
    color: #AD1AAF;
    padding-left: 1px;
    margin-top: 20px;
}

.presentation .socials {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    height: auto;
    list-style: none;
}

.presentation .socials li {
    width: 48px;
    height: 48px;
    background: rgba(7, 0, 0, 0.34);
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(5.6px);
    margin: 0 10px 0 0;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #e4d8e4ad;
    font-size: .9rem;
    opacity: 0.8;
}

.presentation .socials li a {
    color: #e4d8e4ad;
}

.presentation .socials li:hover {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(5.6px);
    border-radius: 1.1rem;
    transform: scale(1.06);
    background-image: linear-gradient(to right bottom, #542176, #642081, #751d8c, #881696, #9b069f);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
    transition: all .4s;
}

.presentation .socials li:hover a {
    color: #ffffff;
}

.astronaut {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eclipse {
    border-radius: 50%;
    width: 500px;
    height: 500px;
    background-color: #5a313d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eclipseBlur {
    border-radius: 50%;
    width: 500px;
    height: 500px;
    box-shadow: inset 0 0 20px 25px rgba(82, 17, 83, 0.3);
    position: absolute;
}

.astronautImage {
    width: 277px;
    height: auto;
    position: relative;
    z-index: 2;
    margin-left: -20px;
    margin-top: -20px;
    animation: floating 3s ease-in-out infinite;
}

.ring {
    width: 505px;
    height: 505px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background-color: transparent;
    animation: rotate 40s linear infinite;
}

.ringTech {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background-color: #210f3b;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #741275;
    font-size: .9rem;
}

.ringTech:nth-child(1) {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ringTech:nth-child(2) {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
}

.ringTech:nth-child(3) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, 0px);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cv {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-family: 'RalewaySemibold';
    font-size: 16px;
    gap: 8px;
}

.cv a {
    text-decoration: none;
    color: #AD1AAF;
}

.whatsappLink {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border: 1px solid #AD1AAF;
    border-radius: 1rem;
    padding: 8px 25px;
    transition: all .5s;
    background-color: transparent;
}

.whatsappLink:hover {
    background-color: #AD1AAF;
    color: #ffffff;
    opacity: .9;
}

.whatsappLink svg {
    font-size: 1.5rem;
}

.downloadCv:hover {
    text-decoration: underline;
}

.waves {
    width: 100%;
    height: auto;
    z-index: 0;
}

.workContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    user-select: none;
}

.workBoxes {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 4%;
}

.workBox {
    width: 250px;
    height: 200px;
    background-color: transparent;
    border: 1px solid #601367;
    border-radius: 1rem;
    font-family: 'RalewaySemiBold';
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: all .3s;
    color: rgb(179, 179, 179);
}

.workBox:hover {
    box-shadow: -3px 2px 65px -5px rgba(173,26,175,0.8);
    -webkit-box-shadow: -3px 2px 65px -5px rgba(173,26,175,0.8);
    -moz-box-shadow: -3px 2px 65px -5px rgba(173,26,175,0.8);
    color: #ffffff;
}

.workBox h3 {
    margin: 0;
}

.worksDescription {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.description {
    width: 50%;
    min-width: 570px;
    height: 110px;
    border-radius: 1rem;
    background-color: #191124;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(173, 26, 175, 0.5);
    gap: 15%;
    z-index: 1;
}

.title {
    font-size: 16px;
    font-family: 'RalewayRegular';
    margin: 0;
}

.desc {
    font-size: 32px;
    font-family: 'RalewayBold';
    margin: 0;
}

@media (max-width: 1300px) {
    .presentation {
        flex-wrap: wrap;
        align-items: center;
    }

    .cv {
        align-items: flex-start;
    }

    .eclipse {
        width: 400px;
        height: 400px;
    }

    .eclipseBlur {
        width: 400px;
        height: 400px;
    }

    .ring {
        width: 405px;
        height: 405px;
    }

    .astronautImage {
        width: 230px;
    }

    .name {
        width: 40%;
    }
}

@media (max-width: 950px) {
    .name {
        width: 30%;
    }

    .astronaut {
        width: 60%;
        justify-content: flex-end;
    }

    .cv {
        width: 40%;
    }
}

@media (max-width: 850px) {
    .greeting {
        font-size: 17px;
    }

    .name h1 {
        font-size: 42px;
    }

    .name h2 {
        font-size: 17px;
    }

    .eclipse {
        width: 350px;
        height: 350px;
    }

    .eclipseBlur {
        width: 350px;
        height: 350px;
    }

    .ring {
        width: 355px;
        height: 355px;
    }

    .astronautImage {
        width: 170px;
    }

    .ringTech {
        width: 45px;
        height: 45px;
    }

    .ringTech svg {
        font-size: 1.6rem;
    }

    .cv {
        font-size: .8rem;
    }

    .cv .whatsappLink {
        font-size: 15px;
    }

    .cv .whatsappLink svg {
        font-size: 1.2rem;
    }

    .workBox {
        width: 200px;
        height: 150px;
        font-size: 17px;
    }
}

@media (max-width: 750px) {
    .presentation {
        margin-top: 80px;
    }

    .astronaut {
        width: 100%;
        justify-content: center;
        order: 3;
    }

    .cv {
        order: 2;
        width: 30%;
        align-items: flex-end;
    }

    .name {
        order: 1;
        width: 50%;
    }

    .description {
        min-width: 0px;
        width: 80%;
    }

    .desc {
        font-size: 25px;
    }
}

@media (max-width: 650px) {
    .whatsappLink {
        white-space: nowrap
    }
}

@media (max-width: 500px) {
    .cv .whatsappLink {
        padding: 8px 10px;
        border-radius: .7rem;
    }

    .greeting {
        font-size: 15px;
    }

    .name h1 {
        font-size: 35px;
    }

    .presentation .socials li {
        width: 40px;
        height: 40px;
        font-size: .7rem;
    }

    .downloadCv {
        white-space: nowrap;
    }

    .astronaut {
        margin-top: -20px;
    }

    .eclipse {
        width: 270px;
        height: 270px;
    }

    .eclipseBlur {
        width: 270px;
        height: 270px;
    }

    .ring {
        width: 275px;
        height: 275px;
    }

    .astronautImage {
        width: 140px;
    }

    .ringTech {
        width: 35px;
        height: 35px;
    }

    .ringTech svg {
        font-size: 1.4rem;
    }

    .name h2 {
        white-space: nowrap;
    }

    .workBox {
        width: 170px;
        height: 120px;
        font-size: 13px;
    }

    .workboxImage {
        width: 50px;
    }

    .description {
        height: 90px;
    }

    .title {
        font-size: 13px;
    }

    .desc {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .presentation {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .name {
        justify-self: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .astronaut {
        order: 2;
        width: 100%;
    }

    .cv {
        order: 3;
        width: 100%;
        align-items: center;
    }

    .workBox {
        width: 120px;
        height: 100px;
        font-size: 11px;
    }

    .workboxImage {
        width: 45px;
    }

    .description {
        padding: 0 20px;
    }
}

@media (max-width: 350px) {
    .eclipse {
        width: 210px;
        height: 210px;
    }

    .eclipseBlur {
        width: 210px;
        height: 210px;
    }

    .ring {
        width: 215px;
        height: 215px;
    }

    .astronautImage {
        width: 110px;
    }

    .ringTech {
        width: 30px;
        height: 30px;
    }

    .ringTech svg {
        font-size: 1.2rem;
    }

    .presentation {
        gap: 30px;
        margin-top: 70px;
    }

    .title {
        font-size: 11px;
    }

    .desc {
        font-size: 15px;
    }
}

@media (max-width: 250px) {
    .astronaut {
        display: none;
    }

    .presentation {
        gap: 15px;
    }

    .workBoxes {
        flex-direction: column;
        gap: 20px;
    }

    .workBox {
        width: 170px;
        height: 120px;
        font-size: 13px;
    }
}