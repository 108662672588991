.skillsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 160px;
}

.skillsContainer h3 {
    font-family: 'RalewayExtraBold';
    font-size: 40px;
    margin-bottom: 16px;
    margin: 0;
    user-select: none;
}

.techsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.techsContainer h4 {
    font-size: 24px;
    font-family: 'RalewayLight';
    color: rgb(255, 255, 255, 0.5);
}

.techs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    gap: 35px;
    margin-top: 15px;
    cursor: pointer;
}

.techs svg {
    font-size: 5.2rem;
    color: rgb(173, 26, 175, 0.5);
    transition: all 0.4s ease-in-out;
}

.techs svg:hover {
    color: rgb(173, 26, 175, 0.9);
    transition: all 0.5s ease-in-out;
    transform: rotate3d(1, 1, 1, 360deg);
}

@media (max-width: 850px) {
    .skillsContainer h3 {
        font-size: 35px;
    }

    .techsContainer h4 {
        font-size: 20px;
    }

    .techs svg {
        font-size: 4.5rem;
    }
}

@media (max-width: 600px) {
    .skillsContainer h3 {
        font-size: 30px;
        text-align: center;
        white-space: nowrap;
    }

    .techsContainer h4 {
        font-size: 18px;
        text-align: center;
    }

    .techs svg {
        font-size: 4rem;
    }
}