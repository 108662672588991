.navbarContainer {
    width: 100%;
    height: auto;
    position: relative;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    z-index: 99;
    user-select: none;
}

.navbar {
    width: 82%;
    height: 40px;
    background-color: #2A2234;
    border-radius: 1rem;
    padding: 15px 20px;
    display: flex;
    align-items: center;
}

.hamNavContainer {
    display: none;
}

#hamNav {
    display: none;
}

.navbarLogo img {
    cursor: pointer;
}

.navbarItems {
    margin-left: auto;
    position: relative;
    left: -55px;
    font-size: 18px;
    font-family: 'RalewayLight';
    display: flex;
    gap: 30px;
    align-items: center;
}

.navbarItems a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.navbarItems a:hover {
    color: #AD1AAF;
    transition: all 0.2s ease-in-out;
}

#toggleLanguage {
    display: none;
}

#labelToggleLanguage {
    color: #ffffff;
    background-color: #090E16;
    width: 55px;
    height: 25px;
    margin: 2px 0 0 35px;
    display: inline-block;
    border-radius: .7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-family: 'RalewayLight';
    font-weight: bold;
    cursor: pointer;
}

.sliderLanguage {
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #AD1AAF;
    margin-left: -10px;
    transition: all 0.3s ease-in-out;
}

.spanToggleLanguage {
    font-size: 12px;
    color: #ffffff;
    user-select: none;
}

#toggleLanguage:checked + #labelToggleLanguage .sliderLanguage {
    transform: translateX(48px);
}

@media (max-width: 875px) {
    .navbarItems {
        font-size: 16px;
        left: -5px;
    }

    #labelToggleLanguage {
        margin: 2px 0 0 5px;
    }
}

@media (max-width: 700px) {
    .navbarItems .navItem {
        display: none;
    } 

    .hamNavContainer {
        display: block;
    }

    #labelHamNav {
        font-size: 1.5rem;
        cursor: pointer;
    }

    .sliderLanguage {
        width: 23px;
        height: 23px;
        margin-left: -8px;
    }

    #labelToggleLanguage {
        width: 38px;
        height: 18px;
    }

    .spanToggleLanguage {
        font-size: 10px;
    }

    #toggleLanguage:checked + #labelToggleLanguage .sliderLanguage {
        transform: translateX(31px);
    }

    .navbarLogo img {
        width: 29px;
    }

    .hamNavItems {
        width: 100px;
        height: 100px;
        background-color: rgb(42, 34, 52, 0.8);
        position: absolute;
        top: 49px;
        right: -10px;
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transition: all 0.2s ease-in-out;
        padding: 5px 10px;
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }

    #hamNav:checked ~ .hamNavItems {
        opacity: 1;
        visibility: visible;
    }
}

@media (max-width: 500px) {
    .navbarContainer {
        margin-top: 25px;
    }

    .navbar {
        padding: 10px 20px;
    }

    .hamNavItems {
        top: 44px;
    }
}