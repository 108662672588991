/* Raleway font-family import */
@font-face {
  font-family: 'RalewayLight';
  src: local('Raleway-Light'),
  url('./fonts/raleway/Raleway-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayMedium';
  src: local('Raleway-Medium'),
  url('./fonts/raleway/Raleway-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayRegular';
  src: local('Raleway-Regular'),
  url('./fonts/raleway/Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewaySemibold';
  src: local('Raleway-SemiBold'),
  url('./fonts/raleway/Raleway-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayBold';
  src: local('Raleway-Bold'),
  url('./fonts/raleway/Raleway-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayExtraBold';
  src: local('Raleway-ExtraBold'),
  url('./fonts/raleway/Raleway-ExtraBold.ttf') format('truetype');
}

html {
  scrollbar-width: none; /* firefox */
}

html::-webkit-scrollbar {
  display: none; /* chrome */
}

body {
  margin: 0;
  color: #ffffff;
  font-family: 'RalewayRegular';
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 1000px;
}

#home {
  position: relative;
  overflow-x: hidden;
}

/* ===== GENERAL STYLING ===== */