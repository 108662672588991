.aboutMeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 160px;
}

.aboutMe {
    width: 84.2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.aboutMe h3 {
    font-family: 'RalewayExtraBold';
    font-size: 40px;
    margin-bottom: 16px;
    margin: 0;
    user-select: none;
}

.aboutMe p {
    margin-left: 7%;
    margin-top: 55px;
    font-size: 32px;
    color: rgb(255, 255, 255, 0.5);
}

.aboutMe p span {
    color: #AD1AAF;
}

@media (max-width: 850px) {
    .aboutMe h3 {
        font-size: 35px;
    }

    .aboutMe p {
        font-size: 28px;
        margin-top: 35px;
    }
}

@media (max-width: 600px) {
    .aboutMe h3 {
        font-size: 30px;
    }

    .aboutMe p {
        font-size: 24px;
        margin-top: 25px;
    }
}

@media (max-width: 400px) {
    .aboutMeContainer {
        margin-top: 100px;
    }

    .aboutMe {
        align-items: center;
    }

    .aboutMe h3 {
        white-space: nowrap;
    }

    .aboutMe p {
        text-align: center;
        margin: 20px 0 0 0;
        font-size: 21.5px;
    }
}